import React, { useEffect, useState } from "react";
import Masonry from 'react-masonry-component';
import { isSafari } from 'react-device-detect';
import Layout from "../components/layout";
import SEO from "../components/seo";
import DemoButtonContainer from "../components/demo-button-container.js"
import "./portfolio.scss";
import portfolio from "../components/data/portfolio.json";

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const nl2br = (str, is_xhtml) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

export default () => {
  const data = portfolio;
  let [logos, setLogos] = useState(null);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  let metaDescriptionValue = '';
  let cmsText = null;

  if (data.gcms && data.gcms.titles && data.gcms.titles.length > 0) {
    for (const title of data.gcms.titles) {
      if (title.indicator === 'portfolio-content' && title.richValue && title.richValue.html) {
        cmsText = <div className="html-text" dangerouslySetInnerHTML={{ __html: nl2br(title.richValue.html) }} />;
        break;
      }
    }
  }
  if (data.gcms && data.gcms.titles && data.gcms.metaDescriptions.length > 0) {
    for (const metaDescription of data.gcms.metaDescriptions) {
      if (metaDescription.indicator === 'portfolio-meta-description') {
        metaDescriptionValue = metaDescription.value;
      }
    }
  }

  useEffect(() => {
    if (isSafari === true) {
      setLogos(shuffleArray(data.allDataJson.edges[0].node.logos).map((logo) => {
        return (
          <a className={logo.class} key={logo.name} href={logo.site} rel="noopener noreferrer" target="_blank">
            <img src={"https://media.travelspirit.nl" + logo.url.replace('carousel/smw', 'original/sm').replace('webp', 'png')} alt={"carousel-image-" + logo.name} loading="lazy" />
          </a>
        );
      }));
    } else {
      setLogos(shuffleArray(data.allDataJson.edges[0].node.logos).map((logo) => {
        return (
          <a className={logo.class} key={logo.name} href={logo.site} rel="noopener noreferrer" target="_blank">
            <img src={"https://media.travelspirit.nl" + logo.url.replace('carousel/smw', 'original/smw')} alt={"carousel-image-" + logo.name} loading="lazy" />
          </a>
        );
      }));
    }

    return () => {
    }
  }, [data.allDataJson.edges])

  return (
    <Layout>
      <SEO title="Portfolio" description={metaDescriptionValue} urlParams="portfolio" />
      <img className="print print-image" src='https://media.travelspirit.nl/original/smw/TravelSpirit_Logo-FC.webp' alt="logo" loading="lazy" />
      <div className="html-container">
        {cmsText}
      </div>
      <div className="portfolio-page-container">
        <Masonry className="portfolio"
          elementType={'ul'}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={true}
          imagesLoadedOptions={imagesLoadedOptions}
        >
          {logos}
        </Masonry>
      </div>
      <div className="modules-demo-container">
        <DemoButtonContainer titles={data.gcms.titles} />
      </div>
    </Layout>
  )
}